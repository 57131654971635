import {
  buildCollection,
  buildEntityCallbacks,
  buildProperty
} from '@camberi/firecms'

//import slugify from 'slugify'

type Project = {
  title: string
  publish: boolean
  order: number
  client?: string
  url?: string
  description?: string
  content: any
  startDate: Date
  endDate: Date
}

const projectCollection = buildCollection<Project>({
  name: 'Projects',
  singularName: 'Project',
  description: 'Project overview',
  path: 'project',
  group: 'Main',
  initialSort: ['order', 'asc'],
  defaultSize: 'xs',

  permissions: ({ authController }) => ({
    edit: true,
    create: true,
    delete: true
  }),


  properties: {

    publish: {
      name: 'Publish?',
      validation: { required: true },
      dataType: 'boolean',
      defaultValue: true
    },


    title: {
      name: 'Title',
      validation: { required: true },
      dataType: 'string'
    },

    order: {
      name: 'Order',
      validation: { required: true },
      dataType: 'number'
    },

    client: {
      name: 'Client',
      markdown: false,
      dataType: 'string',
      columnWidth: 300,
      validation: { required: true }
    },

    startDate: {
      name: 'Start date',
      dataType: 'date',
      mode: 'date',
      validation: { required: false }
    },

    endDate: {
      name: 'End date',
      dataType: 'date',
      mode: 'date',
      validation: { required: false }
    },


    url: {
      name: 'URL',
      markdown: false,
      dataType: 'string',
      columnWidth: 300
    },

    description: {
      name: 'Description',
      markdown: true,
      dataType: 'string',
      columnWidth: 300
    },


    content: buildProperty({
      name: 'Content',
      validation: { required: true },
      dataType: 'array',
      of: {

        ///
        dataType: 'map',
        name: 'Project content',
        validation: { required: false },
        properties: {
          publish: {
            name: 'Publish?',
            dataType: 'boolean',
            defaultValue: true
          },

          position: {
            name: 'Position',
            dataType: 'string',
            enumValues: {
              left: 'Left',
              right: 'Right'
            }
          },

          image: {
            dataType: 'string',
            name: 'Image',
            storage: {
              storeUrl: true,
              storagePath: 'images/projects',
              acceptedFiles: ['image/*']
            }
          },

          paragraph: {
            name: 'Paragraph',
            dataType: 'string',
            markdown: true,
            columnWidth: 300
          },

          textPosition: {
            name: 'textPosition',
            validation: { required: false },
            dataType: 'string',
            enumValues: {
              top: 'Top',
              bottom: 'Bottom'
            }
          }
        }

        ///

      }
    })
  }
})

export default projectCollection
