import { buildCollection } from '@camberi/firecms'

type Slogan = {
  title: string
  byLine: string
  publish: boolean
}

const sloganCollection = buildCollection<Slogan>({
  name: 'Slogan',
  singularName: 'Slogan',
  description: 'Slogan on homepage',
  path: 'slogan',
  group: 'Main',
  defaultSize: 'xs',

  permissions: ({ authController }) => ({
    edit: true,
    create: true,
    delete: true
  }),

  subcollections: [
    //  localeCollection
  ],

  properties: {
    title: {
      name: 'Title',
      validation: { required: true },
      dataType: 'string'
    },
    byLine: {
      name: 'By Line',
      validation: { required: true },
      dataType: 'string'
    },
    publish: {
      name: 'publish',
      dataType: 'boolean'
    }
  }
})

export default sloganCollection
